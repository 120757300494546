// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()

import 'controllers';
require('home/navbar')
require('shared/modal')
require('shared/notify')
require('shared/location')
require('shared/init')
// require('libs/scroll_center')
require('bootstrap')
require('shared/flutter')
import { deliveryModal } from 'shared/delivery_modal'
import { stickyNav } from 'shared/sticky_nav'
import { jwtChecker } from 'shared/jwt_parser';

window.jQuery = $;
window.$ = $;

console.log('env', process.env.callbackEndPoint)

$(document).on('turbolinks:load', function() {
  if(window.location.href.includes('smartcollect.app')) {
    window.location.replace(window.location.href.replace('smartcollect.app', 'vrai.app'))
  }
  if($(window).width() > 1200) {
    setTimeout(() => {
      stickyNav(($(document).scrollTop() - 50) / $(window).height())
    }, 100)

    $(document).on('scroll', () => {
      if($(document).scrollTop() / $(window).height() <= 1 && $(document).scrollTop() > 50 && $(window).width() > 1200) {
        stickyNav(($(document).scrollTop() - 50) / $(window).height())
      }

    })

  }



  $('body').removeClass('loading')
  if($('#shops_index').length) {
    $('.cropped_card img').height($('.cropped_card img').eq(0).innerWidth() * .25)
  }
})

$(document).on('change', '.attribut_fieldset input.check_boxes', function() {
  $(this).parents('fieldset.check_boxes').find('input').not(this).prop('checked', false)
})



$(document).on('click', '.select_delivery_params', (e) => {
  $.get('/cart/edit_delivery')
/*  deliveryModal(e)
  $('.card_delivery_at_container').siblings().css('opacity', '.5')
  $('#modal_calendar_icon').css('opacity', '1')
  $('#modal_icon_cart_logo').css('opacity', '.5')*/
})

$(document).on("turbolinks:before-cache", function() {
  $('body').removeClass('loading')
})

setTimeout(() => {
  setInterval(() => {
    jwtChecker().then((data) => {})
  }, 60000)
}, 2000)

$(document).on('turbolinks:load', function() {
  jwtChecker().then((data) => {})

  if($(window).width() < 900 && $('#verify_user_layout').length == 0 && $('#oauth_callback').length == 0) {

    $('body').css('opacity', 0)
    window.open(`smartcollect:/${window.location.pathname}${window.location.search}`, "_self");
    setTimeout(() => {
      window.location = `${process.env.mobileUrl}${window.location.pathname}${window.location.search}`
    }, 500)

  }
})

$(document).on('click', 'body', function(e) {
  var container = $("#cart_content");
  // if the target of the click isn't the container nor a descendant of the container
  if (!container.is(e.target) && container.has(e.target).length === 0 && $(e.target).get(0).id != 'icon_cart')
  {
    container.hide();
  }
})
